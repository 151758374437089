import { render, staticRenderFns } from "./app-instance-nginx-server-selector.vue?vue&type=template&id=640037b8&scoped=true&"
import script from "./app-instance-nginx-server-selector.vue?vue&type=script&lang=ts&"
export * from "./app-instance-nginx-server-selector.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "640037b8",
  null
  
)

export default component.exports