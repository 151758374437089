import request, { DataApiResult, requestRawResponse } from '@/libs/http-request'
import { BaseHasCreatorAndTimeEntity } from '@/libs/api-request'

/**
 * 附件数据实体
 */
export interface AttachmentFileData extends BaseHasCreatorAndTimeEntity{
    /// 名称
    name?: string;
    /// 附件类型
    fileType?: AttachmentFileType;
    /// 归属团队
    teamId?: number;
    /// 文件路径
    path?: string;
    /// 如果为oss文件，则对应oss的bucket
    ossBucket?: string;
}

/** 附件文件类型 */
export enum AttachmentFileType {
  /** 服务器硬盘文件 */
  DiskFile = 1,
  /** oss服务文件 */
  OssFile
}

/**
 * 分片下载附件请求
 */
export interface RequestAttachmentFilePart{
  fileId: number;
  startPos: number;
  maxSize: number;
  serverId: number;
  requestUrl: string;
}

/**
 * 附件文件api
 */
export class AttachmentFileDataApi {
    private baseUrl='/api/attachment-filedata'
    /// 每片的大小
    public readonly partSize= 100 * 1024;

    /**
     * 上传文件
     * @param name
     * @param deleteId
     * @param file
     * @returns
     */
    upload (name: string, file: FormData, deleteId?: number) {
      return new Promise<AttachmentFileData>((resolve, reject) => {
        request<DataApiResult<AttachmentFileData>>({
          url: `${this.baseUrl}/upload/${name}?deleteId=${deleteId || ''}`,
          method: 'POST',
          data: file
        }).then((res) => {
          resolve(res.data || {})
        }).catch((ex) => reject(ex))
      })
    }

    /**
     * 取得附件文件的大小
     * @param id
     * @returns
     */
    getFileSize (id: number) {
      return new Promise<number>((resolve, reject) => {
        request<DataApiResult<number>>({
          url: `${this.baseUrl}/getFileSize/${id}`,
          method: 'GET'
        }).then((res) => {
          const partcount = res.data! / this.partSize
          resolve(Math.ceil(partcount))
        }).catch((ex) => reject(ex))
      })
    }

    /// 下载指定片的文件数据
    downFilePart (fileId: number, serverId: number, requestUrl: string, partIndex: number) {
      return requestRawResponse<any>({
        url: `${this.baseUrl}/down`,
        method: 'POST',
        data: {
          requestUrl: requestUrl,
          fileId: fileId,
          startPos: partIndex * this.partSize,
          maxSize: this.partSize,
          serverId: serverId
        } as RequestAttachmentFilePart,
        responseType: 'blob'
      })
    }

    /**
     * 根据文件id获取文件
     * @param fileId
     * @returns
     */
    fullDown (fileId: number) {
      return requestRawResponse<any>({
        url: `${this.baseUrl}/fullDown/${fileId}`,
        method: 'POST',
        responseType: 'blob'
      })
    }

    /**
     * 根据名字下载静态文件
     * @param fileName
     * @returns
     */
    downStaticFile (fileName: string) {
      return requestRawResponse<any>({
        url: `${this.baseUrl}/downStaticFile/${fileName}`,
        method: 'POST',
        responseType: 'blob'
      })
    }

    /**
     * 根据文件id获取文件信息
     * @param id
     * @returns
     */
    getItemById (id: number) {
      return new Promise<AttachmentFileData>((resolve, reject) => {
        request<DataApiResult<AttachmentFileData>>({
          url: `${this.baseUrl}/${id}`,
          method: 'GET'
        }).then((res) => {
          resolve(res.data || {})
        }).catch((ex) => reject(ex))
      })
    }
}

export default new AttachmentFileDataApi()
