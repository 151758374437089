import DefaultApiService, { BaseHasTimeEntity } from '@/libs/api-request'

/** nginx实例的服务项配置 */
export interface AppInstanceNginxServer extends BaseHasTimeEntity{
    /// server的名称
    name?: string;

    /// 挂载的nginx实例Id
    nginxInstanceId?: number;

    /// 虚拟主机域名
    serverDomain?: string;

    /// 虚拟主机端口
    serverPort?: number;

    /// 是否使用SSL证书
    useSsl?: boolean;

    /// SSL证书路径
    sslCertPath?: string;

    /// SSL密钥路径
    sslPrivateKeyPath?: string;

    /// nginx实例对应的团队id
    teamId?: number;
}

class AppInstanceNginxServerApi extends DefaultApiService<AppInstanceNginxServer> {
  constructor () {
    super('/api/maintain/appinstanc-nginx-server')
  }

  /**
   * 通过nginx实例id取得所有的server项列表
   *
   * @param nginxInstanceId
   * @return
   */
  getItemsByNginxInstanceId (nginxInstanceId: number) {
    return this.requestList(`getItemsByNginxInstanceId/${nginxInstanceId}`, undefined, 'GET')
  }

  /** 通过id取得数据 */
  getItemById (id: number) {
    return this.request(`${id}`, undefined, 'GET')
  }

  /** 删除server配置项 */
  deleteById (id: number) {
    return this.request(`${id}`, undefined, 'DELETE')
  }
}

export default new AppInstanceNginxServerApi()
