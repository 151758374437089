import { ApplicationType } from './application'
/**
 * 实例
 */

import DefaultApiService, { BaseHasTimeEntity } from '@/libs/api-request'
import { ApiResult, DataApiResult, PagerQueryBean } from '@/libs/http-request'
import { BaseConfig } from './base-config'
import { AppDefaultConfig } from './app-default-config'

/**
 * 实例接口
 */
export interface AppInstance extends BaseHasTimeEntity {
  /** 应用id */
  applicationId: number;
  /** 实例应用的配置模板名称 */
  configTemplateName: string;
  /** 模版提示 */
  defaultConfigAlerts: string;
  /** 当前部署使用的版本 */
  curConfigVersion?: number;
  /** 当前部署使用的分支 */
  curGitBrachName?: string;
  /** 当前部署使用的tag名称 */
  curGitTagName?: string;
  /** 实例uuid，实例的唯一标识，并且用于实例容器名的后缀 */
  uuid: string;
  /** 实例容器名 */
  containerName: string;
  /** 所在服务器id */
  pcServerId?: number;
  /** 归属团队id */
  teamId: number;
  /** 实例名称 */
  name: string;
  /** 是否为主实例 */
  primaryInstance: boolean;
  /** 主实例id，不需要设置主实例的，可以为空 */
  primaryInstanceId?: number;
  /** 实例最后创建成功时间 */
  lastCreateSuccessTime: string;
  /** 应用名称 */
  applicationTitle: string;
  /** 私钥更新时间 */
  privateKeyUpdateTime?: string;
  /** 网关初始化完成 */
  initFinished?: boolean;
  /**
   * 应用类型
   * 1 - 标椎应用-后台应用
   * 2 - 标椎应用-前端应用
   * 3 - 非标准应用
   * 4 - 镜像应用-系统镜像资源
   * 5 - 镜像应用-从docker仓库拉取
   * 6 - 非标准应用前端
   */
  applicationType: ApplicationType;
  /** 应用类型名字 */
  applicationTypeName: string;
  /** 应用类型描述 */
  applicationTypeDest: string;
  /** 服务器名称 */
  pcServerName: string;
  /** 服务器IP地址 */
  pcServerIpAddress: string;
  /** 管理端口 */
  mangerPort: number;
  /** https管理端口 */
  httpsManagerPort: number;
  /** 服务器的通讯加密公钥 */
  privateKey: string;
  /** 服务器uuid */
  pcServerUuid: string;
  /** 项目id */
  projectId: number;
  /** 项目中文名称 */
  projectTitle: string;
  /** 项目英文名称 */
  projectName: string;
  /** 项目组名称 */
  projectGroupName: string;
  /** 镜像id */
  imageId: number;
  /**
   * 镜像类型
   * 1 - 注册中心
   * 2 - 网关
   * 3 - 配置中心
   * 4 - 安全中心
   * 5 - 关系数据库
   * 6 - 缓存数据库
   * 7 - nosql数据库
   * 8 - 消息服务
   * 9 - nginx
   * 99 - 其他
   */
  imageType: number;
  /** 镜像名称 */
  imageName: string;
  /** 应用名称 */
  applicationName?: string;
  /** 项目git地址 */
  gitUrl?: string;
  /** 项目的git账号 */
  gitName?: string;
  /** 项目的git密码 */
  gitPwd?: string;
  /** 项目的git分支 */
  gitBranch?: string;
  /** 项目的git标签 */
  gitTag?: string;
  /** 挂载的nginx实例Id */
  nginxInstanceId?: number;
  /** 挂载的nginx实例应用id */
  nginxApplicationId?: number;
  /** 是否是最新配置 */
  deployNewestConfig?: boolean;
  /** 应用类型标题 */
  applicationTypeTitle?: string;
  /** 挂载的nginx实例名称 */
  nginxAppInstanceName?: string;
  /** 挂载的nginxServer名称 */
  nginxServerName?: string;
  /** 挂载的nginxServer虚拟主机域名 */
  nginxServerDomain?: string;
  /** 前端专用 实例状态 */
  status: number;
}

interface AppInstanceReqVo{
  appId?: number;
  instanceId?: number;
  pcServerId?: number;
  instanceName?: string;
  containerName?: string;
  defaultConfigAlerts?: string;
  configTemplateName?: string;
  configs?: Array<BaseConfig>;
}

export interface CreateOrModiAppInstanceAtNginx{
    /// 实例id
    instanceId?: number;
    /// 对应的应用id
    appId?: number;
    /// 创建的实例名称
    instanceName?: string;
    /// 在服务器中的容器名称
    containerName?: string;

    /// nginx实例id
    nginxInstanceId?: number;
    /// nginx服务id
    nginxServerId?: number;
    /// 使用虚拟主机
    serverDomain?: string;
    serverPort?: number;
    /// 使用ssl
    useSsl?: boolean;
    sslCertPath?: string;
    sslPrivateKeyPath?: string;
    locationIndexFileName?: string;
    locationUri?: string;
}

export interface WebfrontInstanceSaveReqVo{
  // 应用id
  applicationId?: number;
  // 分支
  curGitBrachName?: string;
  // 前端实例名称
  name?: string;
  /// 应用实例id
  appInstanceId?: number;
  // nginx的配置

  // nginx服务器id
  nginxPcServerId?: number;
  // nginx实例id
  nginxInstanceId?: number;
  // 是否使用ssl
  isUseSsl?: boolean;
  // location入口名称
  locationMainFileName?: string;
  // 访问uri
  locationUri?: string;
  // 挂载类型
  mountType?: number;
  // 服务器域名
  serverDomain?: string;
  // 服务器端口
  serverPort?: string;
}

/**
 * 实例服务请求api接口
 */
class AppInstanceApi extends DefaultApiService<AppInstance> {
  /**
   * 取得应用id对应的实例列表
   * @param appId
   * @returns
   */
  listAppInstanceByAppId (appId: number) {
    return this.requestList(`listAppInstanceByAppId/${appId}`, null, 'GET')
  }

  /**
   * 取得应用id对应的实例列表
   * @param appId
   * @returns
   */
  updateAppInstanceStatus (appInstanceId: number, status: number) {
    return this.request(`updateStatus/${appInstanceId}/${status}`, null, 'POST')
  }

  /**
   * 保存实例和配置
   * @param reqVo
   * @returns
   */
  saveInstanceAndConfigs (reqVo: AppInstanceReqVo) {
    return this.request('createNewAppInstance', reqVo, 'POST')
  }

  /**
   * 创建在nginx中的应用实例
   */
  createNewAppInstanceAtNginx (requestData: CreateOrModiAppInstanceAtNginx) {
    return this.request('createNewAppInstanceAtNginx', requestData, 'POST')
  }

  /**
   * 更新nginx类型的实例配置
   */
  updateAppInstanceAtNginx (requestData: CreateOrModiAppInstanceAtNginx) {
    return this.request('updateAppInstanceAtNginx', requestData, 'POST')
  }

  /**
   * 更新应用实例
   */
  updateAppInstance (requestData: AppInstanceReqVo) {
    return this.request('updateAppInstance', requestData, 'POST')
  }

  /**
   * 修改应用实例名称及容器名称
   */
  updateAppInstanceName (id: number, instanceName: string, containerNaem: string) {
    return this.request(`updateAppInstanceName/${id}/${instanceName}/${containerNaem}`, undefined, 'GET')
  }

  /**
   * 通过应用id与pcid取得实例列表
   * @param appId
   * @param pcServerId
   * @returns
   */
  getAppInstanceByAppIdAndServerId (appId: number, pcServerId?: number) {
    return this.requestList('getAppInstanceByAppIdAndServerId', {
      appId: appId,
      pcServerId: pcServerId
    }, 'POST')
  }

  /**
   * 更新实例的最后部署成功时间
   * @param appInstanceId
   * @returns
   */
  updateInstanceLastDeploySuccessTime (appInstanceId: number): Promise<ApiResult> {
    return this.request(`updateInstanceLastDeploySuccessTime/${appInstanceId}`, undefined, 'GET')
  }

  /**
   * 查询nginx实例
   * @returns
   */
  queryNginxInstances () {
    return this.requestList('queryNginxInstance', null, 'POST')
  }

  /**
   * 删除实例
   * @param appInstanceId
   * @returns
   */
  deleteInstance (appInstanceId: number) {
    return this.request(`${appInstanceId}`, null, 'DELETE')
  }

  /**
   * 查询实例明细
   * @returns
   */
  queryWithNginxDetail (query: PagerQueryBean) {
    return this.queryByQueryBean('queryWithNginxDetail', query)
  }

  /**
   * 根据镜像类型获取实例列表
   * @param imageType
   * @returns
   */
  listAppInstanceByImageType (imageType: number) {
    return this.requestList(`listAppInstanceByImageType/${imageType}`, undefined, 'GET')
  }

  /**
   * 查询nginx实例
   * @returns
   */
  listNginxMountedInstance (instanceId: number) {
    return this.requestList(`/listNginxMountedInstance/${instanceId}`, null, 'GET')
  }

  /** 保存实例扩展数据 */
  saveInstanceExtData (instanceId: number, extData: Record<string, any>) {
    return this.request(
      `saveExtData/${instanceId}`,
      extData,
      'POST'
    )
  }

  /** 查询实例扩展数据 */
  queryInstanceExtData (instanceId: number): Promise<DataApiResult<any>> {
    return this.request(
      `queryExtData/${instanceId}`,
      null,
      'GET'
    )
  }

  /** 只保存配置项 */
  saveConfig (instanceId: number, data: AppDefaultConfig[]) {
    return this.request(`saveConfig/${instanceId}`, data, 'POST')
  }

  /** 保存的时候新增config，删除旧config，更新实例信息 */
  saveAndDeleteOldData (data: {configs: AppDefaultConfig[]; instance: AppInstance}) {
    return this.request('saveAndDeleteOldData', data, 'POST')
  }

  /** 获取所有非前端实例 */
  getCurTeamAppInstanceListExceptWeb () {
    return this.requestList('curTeamAppInstanceListExceptWeb', undefined, 'GET')
  }

  /** 根据服务器id和当前实例id获取剩余内存, 返回以M为单位 */
  getPcServerRemainingMemory (pcServerId: number, instanceId: number): any {
    return this.request(`getPcServerRemainingMemory/${pcServerId}/${instanceId}`, null, 'GET')
  }

  constructor () {
    super('/api/maintain/appinstance')
  }
}

export default new AppInstanceApi()
