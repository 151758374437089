
import { Prop, Vue, Component, Model, Watch } from 'vue-property-decorator'
import AppinstanceNginxServerApi from '@/api/maintain/app-instance-nginx-server'

// 实例版本选择组件
@Component({ name: 'AppInstanceNginxServerSelector', components: {} })
export default class AppInstanceNginxServerSelector extends Vue {
  @Model('input')
  readonly value?: number | string

  curValue: number|undefined|string = this.value || 0

  @Prop({
    type: String,
    default: '选择nginx实例'
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  @Prop({
    type: String,
    default: 'default'
  })
  size!: 'small' | 'default' | 'large'

  @Prop({
    type: Number,
    default: undefined,
    required: false
  })
  readonly instanceId!: number

  loading = false

  firstInit=true

  datas: Array<any> = []

  @Watch('instanceId')
  instanceIdChange (newValue?: number) {
    if (!this.firstInit) {
      this.$emit('input', undefined)
      this.$emit('on-select-item', undefined)
      this.curValue = undefined
    }
    this.loadDataFromServer()
  }

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  /** 选中选项事件 */
  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
    for (let i = 0; i < this.datas.length; i++) {
      if (this.datas[i].id === newValue.value) {
        this.$emit('on-select-item', this.datas[i])
        return
      }
    }
  }

  initData () {
    if (!this.value) {
      this.$emit('input', undefined)
    }

    this.loadDataFromServer()
  }

  mounted () {
    this.loadDataFromServer()
  }

  // 加载下拉框选项
  loadDataFromServer () {
    if (!this.instanceId) {
      this.datas = []
      return
    }
    this.loading = true
    AppinstanceNginxServerApi.getItemsByNginxInstanceId(this.instanceId)
      .then((response) => {
        this.datas = response.data || []
        this.curValue = this.value
        this.firstInit = false
        this.loading = false
        const index = this.datas.findIndex((e) => e.id === this.curValue)
        if (index > -1) {
          this.$emit('on-select-item', this.datas[index])
        }
      })
      .catch((err) => {
        this.loading = false
        this.$Notice.error({
          title: '错误',
          desc: '加载下拉框失败:' + err.message
        })
      })
  }
}
