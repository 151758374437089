
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import PcServerApi, { PcServer } from '@/api/maintain/pc-server'
import { getterUserInfo } from '@/store'
import moment from 'moment'

// 团队选择组件
@Component({ name: 'pcServerSelectorInApp', components: {}, mixins: [] })
export default class PcServerSelectorInApp extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number;

  curValue = this.value || 0;

  @Watch('value')
  onValue () {
    this.curValue = this.value || 0
  }

  @Prop({
    type: String,
    default: '输入服务器名称'
  })
  placeholder!: string;

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  forQuery!: boolean;

  @Prop({
    type: String,
    default: 'default'
  })
  size!: 'small' | 'default' | 'large';

  loading = false;

  datas: Array<any> = [];

  firstPcServerId = 0;

  /**
   * 是否开通企业用户
   */
  get opening () {
    const user = getterUserInfo()
    return user.operaExpirationTime && moment(user.operaExpirationTime).isAfter(moment.now())
  }

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit(
      'on-select',
      this.datas.find((e) => e.value === newValue.value)
    )
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData (userId: number) {
    this.$emit('input', undefined)
    this.curValue = -1
    this.userId = userId
    if (this.userId) {
      this.loadDataFromServer()
    }
  }

  mounted () {
    if (!this.forQuery) {
      PcServerApi.getFirstPcServerId().then((res) => {
        this.firstPcServerId = res.data || 0
        this.loadDataFromServer()
      })
    } else {
      this.loadDataFromServer()
    }
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    const queryData = {
      page: 1,
      pageSize: 100,
      sorts: []
    }
    PcServerApi.query(queryData)
      .then((response) => {
        const data = response.data?.items
        if (this.opening || this.forQuery) {
          this.datas = data!.map((e) => {
            return {
              value: e.id,
              label: e.name + '（' + e.ipAddress + '）'
            }
          })
        } else {
          this.datas = data!
            .filter((e) => e.id === this.firstPcServerId)
            .map((e) => {
              return {
                value: e.id,
                label: e.name + '（' + e.ipAddress + '）'
              }
            })
        }
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '加载服务器下拉框失败:' + err.message
        })
      })
    this.loading = false
  }
}
