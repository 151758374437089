
import { Vue, Component, Prop } from 'vue-property-decorator'
import AttachmentFileDataApi from '@/api/maintain/attachment-filedata'

@Component({
  name: 'uploadButton'
})
export default class UploadButton extends Vue {
  /** 上传文本 */
  @Prop({
    type: String,
    required: false,
    default: '上传文件'
  })
  uploadText!: string;

  /** 重新上传文本 */
  @Prop({
    type: String,
    required: false,
    default: '重新上传'
  })
  repeatUploadText!: string;

  /** 下载文件路径 */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  downLoadPath!: string;

  /** 是否需要禁用 */
  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

   /** 可上传文件类型 */
   @Prop({
     type: String,
     default: undefined
   })
  accept?: string

  loading = false

  /// 上传
  handleUpload (file: File) {
    this.loading = true
    this.$emit('onLoading', true)
    /// 上传 包装为formData
    const formData = new FormData()
    formData.append('file', file)
    AttachmentFileDataApi.upload(file.name, formData)
      .then((res) => {
        this.$emit('onOk', res)
        this.$Notice.success({ title: '成功', desc: '上传成功' })
      }).catch((err) => {
        this.$Notice.error({ title: '失败', desc: err.message })
      }).finally(() => {
        this.$emit('onLoading', false)
        this.loading = false
      })
    return false
  }

  /// 下载
  down () {
    if (this.downLoadPath) {
      const arr = this.downLoadPath.split(':')

      switch (arr[0]) {
        case 'attr':
          this.downByAttr(parseInt(arr[1]))
          break
        case 'static':
        case 'placeholder':
          this.downByStatic(arr[1])
          break

        default:
          this.$Message.error('暂不支持该类型文件')
      }
    } else {
      this.$Message.error('还未上传过文件, 无法下载')
    }
  }

  /// 数据库文件下载
  downByAttr (id: number) {
    /// 获取文件信息
    AttachmentFileDataApi.getItemById(id).then((data) => {
      const link = document.createElement('a')
      link.download = data.name || 'error'
      /// 下载文件
      AttachmentFileDataApi.fullDown(id).then((res) => {
        const blob = new Blob([res.data])
        link.href = window.URL.createObjectURL(blob)
        link.click()
        window.URL.revokeObjectURL(link.href)

        this.$Message.success('下载成功')
      }).catch(err => {
        this.$Message.error('下载失败' + err.message)
      })
    })
  }

  /// 静态文件下载
  downByStatic (fileName: string) {
    const link = document.createElement('a')
    link.download = fileName
    /// 下载文件
    AttachmentFileDataApi.downStaticFile(fileName).then((res) => {
      const blob = new Blob([res.data])
      link.href = window.URL.createObjectURL(blob)
      link.click()
      window.URL.revokeObjectURL(link.href)

      this.$Message.success('下载成功')
    }).catch(err => {
      this.$Message.error('下载失败' + err.message)
    })
  }
}
